import * as Sentry from '@sentry/gatsby'

Sentry.init({
  dsn:
    'https://190c0733d684682beb5edfe565249051@o4507842364768256.ingest.de.sentry.io/4507842370207824',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/hakanhellstrom\.se/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
})
